function onFetchInit(data) {
  let form = {}
  data.forEach((e, i) => {
    form['name' + i] = e.name
    form['image2' + i] = e.image2
    form['image3' + i] = e.image3
    form['status' + i] = e.status
    form['maxVideo' + i] = e.maxVideo
  })
  return {
    form,
    typeData: []
  }
}

function onSubmit({ detail, form }) {
  return {
    farmDigitalBaseList: detail.map((e, i) => {
      return {
        ...e,
        name: form['name' + i],
        image2: form['image2' + i],
        image3: form['image3' + i],
        status: form['status' + i],
        maxVideo: form['maxVideo' + i]
      }
    })
  }
}

export default {
  type: 'cardForm',
  url: '/config/farmDigitalBase/list',
  edit: '/config/farmDigitalBase/updateBatch',
  onFetchInit,
  onSubmit,
  data: (netData = []) => {
    if (!Array.isArray(netData)) return []
    return (netData || []).map((e, i) => {
      return {
        form: [
          {
            name: ['数字基地', '基地实时景色'][i] + '名称',
            type: 'input',
            key: 'name' + i,
            display: true
          },
          {
            name: '视频最大数量',
            type: 'input',
            key: 'maxVideo' + i,
            display: i == 1
          },
          {
            name: 'ICON',
            type: 'iconUpload',
            typeData: [
              {
                key: 'image2' + i,
                desc: '@2x   xhdpi <br/>上传尺寸220*384px'
              },
            ],
            display: true
          },
          {
            name: '显示',
            type: 'switch',
            key: 'status' + i,
            display: true
          }
        ].filter(e => e.display)
      }
    })
  }
}
